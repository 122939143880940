'use client';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';
import Autoplay from 'embla-carousel-autoplay';

export const HeroDietsCarousel = ({ diets }: { diets: React.ReactNode[] }) => {
  return (
    <Carousel
      orientation='vertical'
      opts={{
        align: 'start',
        loop: true,
      }}
      plugins={[
        Autoplay({
          delay: 5000,
          stopOnMouseEnter: true,
        }),
      ]}
    >
      <CarouselContent className='mt-0 h-[80px] md:h-[168px]'>
        {diets.map((diet, index) => (
          <CarouselItem
            key={index}
            className='mb-2 pt-0 shadow-none md:basis-1/4'
          >
            {diet}
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};
