'use client';

import { buttonVariants } from '@/components/ui/button';
import { getPathname } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

type MultidietCtaButtonProps = {
  buttonLabel?: string;
  testId?: string;
};

export const MultidietCtaButton = ({
  buttonLabel,
  testId = '',
}: MultidietCtaButtonProps) => {
  const t = useTranslations('staticBuilder.multidiet');

  const handleClick = () => {
    localStorage.setItem('prevPath', window.location.hostname);
  };

  return (
    <Link
      onClick={handleClick}
      href={getPathname({ href: '/multidiet-wizard', locale: 'pl' })}
      className={cn(
        buttonVariants({
          variant: 'secondary',
          className: 'text-primary px-12 py-4 max-md:w-full',
        }),
      )}
      data-pw={testId}
    >
      <span>{buttonLabel || t('cta')}</span>
      <IconArrowNarrowRight stroke={1.25} className='ml-2' />
    </Link>
  );
};
