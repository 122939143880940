'use client';

import { Img } from './image';
import {
  type BlocksContent,
  BlocksRenderer as StrapiBlocksRenderer,
} from '@strapi/blocks-react-renderer';

export const BlockRenderer = ({
  content,
  dangerouslySetInnerHTML,
}: {
  readonly content?: BlocksContent;
  dangerouslySetInnerHTML?: boolean;
}) => {
  if (!content) return null;
  return (
    <StrapiBlocksRenderer
      content={content}
      blocks={{
        image: ({ image }) => {
          return (
            <Img
              src={image.url}
              width={image.width}
              height={image.height}
              alt={image.alternativeText || ''}
            />
          );
        },
        paragraph: ({ children }) => {
          if (dangerouslySetInnerHTML) {
            // This is a workaround to render HTML content in a paragraph block
            const dangerouslyParagraph = children as {
              props: { text: string };
            }[];
            const text = dangerouslyParagraph?.[0]?.props.text as string;
            return <p dangerouslySetInnerHTML={{ __html: text }} />;
          }
          return <p>{children}</p>;
        },
      }}
    />
  );
};
