'use client';

import ClassicLoader from '@/components/common/classic-loader';
import { FormInput } from '@/components/form';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { getAvailabilityZone } from '@/features/localizer/api/get-availablity-zone';
import { LocalizerDialog } from '@/features/localizer/components/localizer-dialog';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  postCode: z
    .string()
    .regex(new RegExp(/^\d{2}-\d{3}?$/), 'localizer.form.errors.postCodeFormat')
    .min(1, { message: 'localizer.form.errors.postCode' }),
  city: z.string().min(1, { message: 'localizer.form.errors.city' }),
});

export const LocalizerForm = () => {
  const t = useTranslations('localizer');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<'success' | 'error' | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      postCode: '',
      city: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      setIsLoading(true);
      await getAvailabilityZone(data);
      setStatus('success');
      setOpen(true);
    } catch (error) {
      console.error(error);
      setStatus('error');
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='flex flex-col md:flex-row gap-2 xl:gap-4 mt-4 md:mt-0'
        >
          <FormInput
            form={form}
            fieldName='postCode'
            fieldPlaceholder={t('form.fields.postCode')}
            inputClassName='h-14'
            className='lg:w-1/4 lg:min-w-[133px]'
          />
          <div className='flex-1'>
            <FormInput
              form={form}
              fieldName='city'
              fieldPlaceholder={t('form.fields.city')}
              inputClassName='h-14'
            />
          </div>
          <Button
            type='submit'
            className='px-8 h-14 mt-3 md:mt-0'
            disabled={isLoading}
          >
            {t('form.submit')}
            {isLoading ? (
              <ClassicLoader className='w-4 h-4 ml-2 border-white border-t-transparent' />
            ) : (
              <IconArrowNarrowRight stroke={1.25} className='ml-2' />
            )}
          </Button>
        </form>
      </Form>
      {status && (
        <LocalizerDialog
          open={open}
          onOpenChange={setOpen}
          city={form.getValues('city')}
          postCode={form.getValues('postCode')}
          status={status}
        />
      )}
    </>
  );
};
