import XIcon from '@/assets/icons/x.svg';
import { Button } from '@/components/ui/button';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useTranslations } from 'next-intl';

export const LocalizerStatusError = ({
  postCode,
  city,
}: {
  postCode: string;
  city: string;
}) => {
  const t = useTranslations('localizer');

  return (
    <DialogContent className='max-w-[calc(100%-2rem)] rounded-xl sm:max-w-[500px]'>
      <DialogHeader className='mb-6'>
        <DialogTitle className='text-xl md:text-2xl font-medium -mb-1 text-left'>
          {t('fail.title')}
        </DialogTitle>
        <DialogDescription className='hidden'></DialogDescription>
      </DialogHeader>
      <div className='flex items-center gap-3 mb-2'>
        <div className='w-10 h-10 md:w-11 md:h-11 shrink-0 rounded-full bg-brand-red/95 flex items-center justify-center'>
          <XIcon className='text-white [&>path]:stroke-current' />
        </div>
        <p className='text-lg md:text-xl font-medium'>
          <span>{postCode}</span> <span>{city}</span>
        </p>
      </div>
      {/* Disabled until email data collection is established
      <p className='md:text-lg opacity-45'>{t('fail.description')}</p>
      <LocalizerEmailForm /> */}
      <DialogFooter className='mt-6'>
        <DialogClose asChild>
          <Button variant='secondary' className='w-full h-14'>
            {t('fail.button')}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};
