import { Dialog } from '@/components/ui/dialog';
import { LocalizerStatusError } from '@/features/localizer/components/localizer-status-error';
import { LocalizerStatusSuccess } from '@/features/localizer/components/localizer-status-success';

export const LocalizerDialog = ({
  open,
  onOpenChange,
  city,
  postCode,
  status,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  city: string;
  postCode: string;
  status: 'success' | 'error';
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {status === 'success' && (
        <LocalizerStatusSuccess postCode={postCode} city={city} />
      )}
      {status === 'error' && (
        <LocalizerStatusError postCode={postCode} city={city} />
      )}
    </Dialog>
  );
};
