'use client';

import IconPlay from '@/assets/icons/play.svg';
import X from '@/assets/icons/x.svg';
import { Button } from '@/components/ui/button';
import { Dialog, DialogClose, DialogContent } from '@/components/ui/dialog';
import { useState } from 'react';

export const HeroLandingVideo = ({ youtubeId }: { youtubeId: string }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handlePlayClick = () => {
    setIsVideoOpen(true);
  };

  return (
    <>
      <Button
        onClick={handlePlayClick}
        className='bg-brand-red hover:bg-brand-red/80 animation-pulse absolute left-1/2 top-1/2 h-20 w-20 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full'
      >
        <IconPlay />
      </Button>
      <Dialog open={isVideoOpen} onOpenChange={setIsVideoOpen}>
        <DialogContent className='flex h-dvh max-w-full flex-col items-center justify-center border-0 bg-transparent p-0'>
          <DialogClose className='fixed right-4 top-4 z-10'>
            <Button
              variant='ghost'
              size='icon'
              className='h-8 w-8 rounded-full bg-black/50 text-white hover:bg-black/70'
            >
              <X className='h-4 w-4' />
              <span className='sr-only'>Close</span>
            </Button>
          </DialogClose>
          <div className='aspect-video max-h-[90vh] w-full sm:max-w-[90vw]'>
            <iframe
              width='100%'
              height='100%'
              src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
