import TickIcon from '@/assets/icons/tick.svg';
import { buttonVariants } from '@/components/ui/button';
import {
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { getLocaleForPathname, getPathname } from '@/i18n/navigation';
import { cn } from '@/lib/utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

export const LocalizerStatusSuccess = ({
  postCode,
  city,
}: {
  postCode: string;
  city: string;
}) => {
  const t = useTranslations('localizer');
  const locale = getLocaleForPathname();

  const pathname = getPathname({
    href: { pathname: '/diets' },
    locale,
  });

  return (
    <DialogContent className='max-w-[calc(100%-2rem)] rounded-xl sm:max-w-[500px]'>
      <DialogHeader className='mb-6'>
        <DialogTitle className='text-xl md:text-2xl font-medium -mb-1 text-left'>
          {t('success.title')}
        </DialogTitle>
        <DialogDescription className='text-2xl font-bold text-primary '>
          {t('success.description')}
        </DialogDescription>
      </DialogHeader>
      <div className='flex items-center gap-3 mb-2'>
        <div className='w-10 h-10 md:w-11 md:h-11 shrink-0 rounded-full bg-brand-green/95 flex items-center justify-center'>
          <TickIcon className='text-white' />
        </div>
        <p className='text-lg md:text-xl font-medium'>
          <span>{postCode}</span> <span>{city}</span>
        </p>
      </div>
      <p className='text-lg md:text-xl opacity-45'>{t('positive.info')}</p>
      <DialogFooter className='mt-6'>
        <Link
          href={pathname}
          className={cn(
            buttonVariants({ variant: 'secondary' }),
            'w-full h-14',
          )}
        >
          {t('success.button')}
        </Link>
      </DialogFooter>
    </DialogContent>
  );
};
