import { type AvailabilityZoneParams } from '../types/localizer.types';
import { GET } from '@/lib/methods';
import { objectToQueryString } from '@/lib/utilities';

export const getAvailabilityZone = async (params: AvailabilityZoneParams) => {
  const queryString = objectToQueryString(params);
  return GET<Response>({
    url:
      process.env.NEXT_PUBLIC_FOOAPP_URL +
      `/availability-zones/exists${queryString}`,
    params: {
      next: {
        tags: ['availability-zone', queryString],
        revalidate: 3600,
      },
    },
  });
};
